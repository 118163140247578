import withName from "../../hoc/withName";
import { useRef, useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { OverlayPanel } from "primereact/overlaypanel";

const CalendarPrime = ({t,value=null,setValue=null, maxDaysRange=null,presets=null,  ...calendarProps }) => {
    let minDateRange = null;
    let maxDateRange = null;
    const [valueInput, setValueInput] = useState();
    const today = new Date();
    const menu = useRef(null);
    const op = useRef(null);
    const applyPreset = (data) => {
        if(data.item.custom){
            op.current.toggle(data.originalEvent);
        }else{
            setValue([data.item.start, data.item.end]);
        }
    };
    const presetsTemplates = [
        { label: t("forms.presetLast7"), start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6), end: today, command:applyPreset },
        { label: t("forms.presetThisMonth"), start: new Date(new Date().setDate(1)), end: today, command:applyPreset },
        { label: t("forms.presetLast30"), start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 29), end: today, command:applyPreset },
        { label: t("forms.presetLast60"), start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 59), end: today, command:applyPreset },
        { label: t("forms.presetLast90"), start: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 89), end: today, command:applyPreset },
        { label: t("daterangepicker.customRange"), custom:true, command:applyPreset }
    ];
    if(value?.[0] && !calendarProps.timeOnly){
        minDateRange = new Date(value[0]);
        maxDateRange = new Date(value[0]);
        minDateRange.setDate(minDateRange.getDate() - maxDaysRange);
        maxDateRange.setDate(maxDateRange.getDate() + maxDaysRange);

    }
    const formatInput = (dates) => {
        let newValueInput = null;
        if(dates?.[0]){
            newValueInput = dates[0].toLocaleDateString("es-ES", {day: "2-digit", month: "2-digit", year: "numeric"});
        }
        if(dates?.[1]){
            newValueInput = newValueInput + " - " + dates[1].toLocaleDateString("es-ES", {day: "2-digit", month: "2-digit", year: "numeric"});

        }else{
            newValueInput = newValueInput  + " - " + "dd/mm/yyyy";
        }
        return newValueInput;
    };
    const extractDatesFromString = (str) => {
        if(str){
            const strings = str?.split(" - ");
            const regex = /(\d{2})\/(\d{2})\/(\d{4})/;
            let date1 = null;
            let date2 = null;
            let matches = strings?.[0]?.match(regex);
            if (matches) {
                const [dia, mes, año] = matches[0].split("/").map(Number);
                date1 = new Date(año, mes - 1, dia);
            }
            matches = strings?.[1]?.match(regex);
            if (matches) {
                const [dia, mes, año] = matches[0].split("/").map(Number);
                date2 = new Date(año, mes - 1, dia);
            }
            return [date1,date2];
        }
    };
    useEffect(() => {
        if(value?.[0] && value?.[1]  && !calendarProps.timeOnly){
            setValueInput(formatInput(value));
        }
    }, [value]);

    const datesAreEqual = (date1, date2) => {
        const day1 = date1?.getDate();
        const month1 = date1?.getMonth();
        const year1 = date1?.getFullYear();

        const day2 = date2?.getDate();
        const month2 = date2?.getMonth();
        const year2 = date2?.getFullYear();

        return day1 === day2 && month1 === month2 && year1 === year2;
    };

    if(presets){
        return(
            <>
                <div className="p-inputgroup flex-1">
                    <OverlayPanel ref={op}>
                        <Calendar
                            className="calendar-prime" panelClassName='calendar-panel-prime' inline
                            minDate={value?.[0] && maxDaysRange?minDateRange:null} maxDate={value?.[0] && maxDaysRange?maxDateRange:null}
                            numberOfMonths={calendarProps?.selectionMode==="range"?2:1} value={value}
                            placeholder={t(`daterangepicker.${(calendarProps?.selectionMode==="range")?"placeholder":"fecha"}`)}
                            {...calendarProps}
                        />
                    </OverlayPanel>
                    <InputMask
                        onClick={(e) => op.current.toggle(e)} onChange={(e) => {
                            setValueInput(e.target.value);
                            const newDates = extractDatesFromString(e.target.value);
                            if( !datesAreEqual(value?.[0], newDates?.[0]) || !datesAreEqual(value?.[1], newDates?.[1]) ){
                                setValue(newDates);
                            }
                        }} value={valueInput}
                        className="calendar-preset-input" mask="99/99/9999 - 99/99/9999" slotChar="mm/dd/yyyy - mm/dd/yyyy"
                        placeholder={t(`daterangepicker.${(calendarProps?.selectionMode==="range")?"placeholder":"fecha"}`)}
                    />
                    <TieredMenu className="calendar-tieredMenu" model={presetsTemplates} popup ref={menu} breakpoint="767px" />
                    <Button icon="pi pi-calendar" className="calendar-preset-button" onClick={(e) => menu.current.toggle(e)} />
                </div>
            </>
        );
    }
    return (
        <>
            <Calendar
                className="calendar-prime" panelClassName='calendar-panel-prime' showIcon
                minDate={value?.[0] && maxDaysRange?minDateRange:null} maxDate={value?.[0] && maxDaysRange?maxDateRange:null}
                numberOfMonths={calendarProps?.selectionMode==="range"?2:1} value={value}
                placeholder={t(`daterangepicker.${(calendarProps?.selectionMode==="range")?"placeholder":"fecha"}`)}
                {...calendarProps}
            />
        </>
    );
};

export default withName(CalendarPrime, "CalendarPrime");
