
// http://www.w3.org/TR/html5/forms.html#valid-e-mail-address, but with TLD
const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// https://github.com/googlei18n/libphonenumber/blob/master/javascript/i18n/phonenumbers/phonenumberutil.js, VALID_PHONE_NUMBER_PATTERN
// eslint-disable-next-line no-irregular-whitespace,no-useless-escape
const regexPhone = /^[0-9０-９٠-٩۰-۹]{2}$|^[+＋]*(?:[-x‐-―−ー－-／  ­​⁠　()（）［］.\[\]/~⁓∼～*]*[0-9０-９٠-٩۰-۹]){3,}[-x‐-―−ー－-／  ­​⁠　()（）［］.\[\]/~⁓∼～0-9０-９٠-٩۰-۹]*(?:;ext=([0-9０-９٠-٩۰-۹]{1,7})|[  \t,]*(?:e?xt(?:ensi(?:ó?|ó))?n?|ｅ?ｘｔｎ?|[,xｘ#＃~～]|int|anexo|ｉｎｔ)[:\.．]?[  \t,-]*([0-9０-９٠-٩۰-۹]{1,7})#?|[- ]+([0-9０-９٠-٩۰-۹]{1,5})#)?$/i;

const validateOptionalRut = (value) => {
    if (!value) return;
    const nValue = typeof value === "string" ? Number(value) : value;
    if (!Number.isInteger(nValue)) {
        return "validation.invalidRut";
    } else if (nValue > 99999999) {
        return "validation.largeRut";
    }
    return;
};

const validateOptionalDV = (value) => {
    if (value && value.length > 1) {
        return "validation.invalidDV";
    }
    return;
};

const validateEmail50 = (value) => {
    if (!value || !value.length) {
        return "validation.required";
    } else if (value.length > 50) {
        return "validation.length50";
    } else if (!regexEmail.test(value)) {
        return "validation.invalidEmail";
    }
    return;
};

const validateString75 = (value) => {
    if (value?.length > 75) {
        return "validation.length75";
    }
    return;
};

const validateString50 = (value) => {
    if (!value || !value.length) {
        return "validation.required";
    } else if (value.length > 50) {
        return "validation.length50";
    }
    return;
};

const validatePhone50 = (value) => {
    if (value.length > 50) {
        return "validation.length50";
    } else if (!regexPhone.test(value)) {
        return "validation.invalidPhone";
    }
    return;
};

const validateOptional50 = (value) => {
    if (value && value.length > 50) {
        return "validation.length50";
    }
    return;
};

const validateOptional75 = (value) => {
    if (value && value.length > 50) {
        return "validation.length75";
    }
    return;
};

const validateSomething = (value) => {
    if (value==null && value==undefined) return "validation.required";
    return;
};

const validateFullRut = (value1, value2) => {
    const Fn = {
        // Valida el rut con su cadena completa "XXXXXXXX-X"
        validaRut: function (rutCompleto) {
            rutCompleto = rutCompleto.replace("‐", "-");
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
                return false;
            const tmp = rutCompleto.split("-");
            let digv = tmp[1];
            const rut = tmp[0];
            if (digv == "K") digv = "k";

            return (Fn.dv(rut) == digv);
        },
        dv: function (T) {
            let M = 0, S = 1;
            for (; T; T = Math.floor(T / 10))
                S = (S + T % 10 * (9 - M++ % 6)) % 11;
            return S ? S - 1 : "k";
        }
    };

    if (!value1 && !value2) {
        return "validation.required";
    }
    if (value1 && !Fn.validaRut(value1)) {
        return "rut-validation.invalidFullRut";
    }
    if (value1 && !value2) {
        return "pass-validation.required";
    }
    if (value2 && value2.length < 4) {
        return "pass-validation.minLength4";
    }
    if (value2 && !value1) {
        return "user-validation.required";
    }


};

const validatePassWithUser = (value1, value2) => {
    if (value1 && !value2) {
        return "validation.required";
    }
};

const validatePassword = (value) => {
    if (!value) return "validation.required";
    else if (value.length < 8) return "validation.minLength8";
};

export {
    validateOptionalRut, validateOptionalDV, validateEmail50, validateString50, validateString75,
    validatePhone50, validateOptional50, validateOptional75, validateSomething, validateFullRut, validatePassWithUser, validatePassword
};
